<template>
  <div
    class="course-card-component flex items-center bg-white p-3 rounded-4 gap-3 cursor-pointer group transition duration-200 ease-in-out"
    @click="handleClickCourse">
    <div
      class="overflow-hidden inline-block h-20 w-30 max-h-20 max-w-30 rounded-2 bg-secondary-200">
      <div
        v-if="course?.cover"
        class="relative bg-cover bg-right h-full"
        :style="{
          backgroundImage: `url(${course?.cover})`,
        }"></div>
    </div>
    <div v-if="loading" class="flex flex-col gap-2 flex-1">
      <Skeleton width="30%" height="1rem" />
      <Skeleton width="80%" height="1rem" />
    </div>
    <StudentCourseInfo
      v-else-if="type === 'student'"
      :course="course"
      :group="group"
      :progress="progress"/>
    <TeacherCourseInfo
      v-else-if="type === 'teacher'"
      :course="course"
      :group="group"/>
    <LibraryCourseInfo v-else-if="type === 'library'" :course="course" />
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { useRouter } from 'vue-router';
import { type ICourse } from '../../types/interfaces';
import StudentCourseInfo from './views/StudentCourseInfo.vue';
import TeacherCourseInfo from './views/TeacherCourseInfo.vue';
import LibraryCourseInfo from './views/LibraryCourseInfo.vue';
import Skeleton from 'primevue/skeleton';

type CardView = 'student' | 'teacher' | 'library';

const props = defineProps({
  type: {
    required: true,
    type: String as PropType<CardView>,
  },
  course: {
    type: Object as PropType<ICourse>,
    default: () => {},
  },
  progress: {
    type: Object,
    default: () => {},
  },
  group: {
    type: Object,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const handleClickCourse = () => {
  const options = {
    student: {
      name: 'course-dashboard',
      params: { course: props.course.id, group: props.group?.id },
    },
    teacher: {
      name:
        props.course.type === 'survey' ? 'survey-progress' : 'course-progress',
      params: { course: props.course.id, group: props.group?.id },
    },
    library: {
      name:
        props.course.type === 'survey' ? 'survey-library' : 'course-library',
      params: { course: props.course.id },
    },
  };

  router.push(options[props.type]);
};
</script>

<style scoped lang="scss">
.course-card-component {
  &:hover {
    @apply shadow-light;
    > div:first-child {
      > div {
        @apply scale-125;
        transition: transform 1s ease-in-out;
      }
    }
  }
  &:active {
    @apply bg-[#F4F7FB];
  }
}
</style>
